// with polyfills
import 'core-js/stable'
import 'regenerator-runtime/runtime'

import Vue from 'vue'
import App from './App.vue'
import router from './router'
import permission from './directive/permission'
import style from './directive/style'
import store from './store/'
import i18n from './locales'
import { VueAxios } from './utils/request'
import ProLayout, { PageHeaderWrapper } from '@/components/ProLayout'
import FooterToolBar from '@/components/FooterToolbar'
import FileUpload from '@/components/FileUpload'
import CosUpload from '@/components/FileUpload/indexCos'
import themePluginConfig from '../config/themePluginConfig'
import { TableSetting } from '@/components'
// 字典数据组件
import DictData from '@/components/DictData'
// 字典标签组件
import DictTag from '@/components/DictTag'

import bootstrap from './core/bootstrap'
import './core/lazy_use' // use lazy load components
import './permission' // permission control
import './utils/filter' // global filter
import './global.less' // global style
import '@/assets/css/main.css' // ruoyi css main.css
import { getDicts } from '@/api/system/dict/data'
import { getConfigKey } from '@/api/system/config'
import { parseTime, resetForm, addDateRange, selectDictLabel, selectDictLabels, handleTree, tableSorter } from '@/utils/ruoyi'
import { download } from '@/utils/request'
// import { TUIComponents, TUICore, genTestUserSig } from './TUIKit';
// import { TUICallKit } from '@tencentcloud/call-uikit-vue';
// import storage from 'store'
// const SDKAppID = 1600016772; // Your SDKAppID
// const secretKey = 'efc38493480a388e88c41dc1c6a3af2c0f62a01b114485a1660fc12751c116e5'; //Your secretKey
// const userID = storage.get(LOGIN_USERID); // User ID

// init TUIKit
// const TUIKit = TUICore.init({
//   SDKAppID,
// });
// TUIKit add TUIComponents
// TUIKit.use(TUIComponents);
// TUIKit add TUICallKit
// TUIKit.use(TUICallKit);

// login TUIKit
// TUIKit.login({
//   userID: userID,
//   userSig: genTestUserSig({
//     SDKAppID,
//     secretKey,
//     userID,
//   }).userSig, // The password with which the user logs in to IM. It is the ciphertext generated by encrypting information such as userID.For the detailed generation method, see Generating UserSig
// });


// 在main.js中全局引入
import video from 'vue-video-player/src';
import 'vue-video-player/src/custom-theme.css'
import 'video.js/dist/video-js.css'



// 全局方法挂载
Vue.prototype.getDicts = getDicts
Vue.prototype.getConfigKey = getConfigKey
Vue.prototype.parseTime = parseTime
Vue.prototype.resetForm = resetForm
Vue.prototype.addDateRange = addDateRange
Vue.prototype.selectDictLabel = selectDictLabel
Vue.prototype.selectDictLabels = selectDictLabels
Vue.prototype.download = download
Vue.prototype.handleTree = handleTree
Vue.prototype.tableSorter = tableSorter
Vue.config.productionTip = false

// Vue.use(TUIKit)
// mount axios to `Vue.$http` and `this.$http`
Vue.use(VueAxios)
// use pro-layout components
Vue.component('pro-layout', ProLayout)
Vue.component('page-container', PageHeaderWrapper)
Vue.component('page-header-wrapper', PageHeaderWrapper)
Vue.component('footer-tool-bar', FooterToolBar)
Vue.component('file-upload', FileUpload)
import Viewer from 'v-viewer'
import 'viewerjs/dist/viewer.css'
import {LOGIN_USERID} from "@/store/mutation-types";

Vue.component('cos-upload',CosUpload)
Vue.component('table-setting', TableSetting)
Vue.component('dict-tag', DictTag)

Vue.use(permission)
Vue.use(style)
DictData.install()

Vue.use(Viewer)
Viewer.setDefaults({
  Options: {
    'inline': true, //启用lnline模式
    'button': true, //显示右上角关闭按钮
    'navbar': true, //显示缩略图导航
    'title': true, //显示当前图片的标题
    'toolbar': true, //显示工具栏
    'tooltip': true, //显示缩放百分比
    'movable': true, //显示可移动
    'zoomable': true, //图片是否可缩放
    'rotatable': true, //图片是否可旋转
    'scalable': true, //图片是否可翻转
    'transition': true, //使用css3过度
    'fullscreen': true, //播放时是否全屏
    'keyboard': true, //是否支持键盘
    'url': 'data-source' //设置大图片的url
  }
})

window.umi_plugin_ant_themeVar = themePluginConfig.theme
new Vue({
  router,
  store,
  i18n,
  // init localstorage, vuex
  created: bootstrap,
  render: h => h(App)
}).$mount('#app')
