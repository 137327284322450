import { render, staticRenderFns } from "./indexCos.vue?vue&type=template&id=390407a0&scoped=true"
import script from "./indexCos.vue?vue&type=script&lang=js"
export * from "./indexCos.vue?vue&type=script&lang=js"
import style0 from "./indexCos.vue?vue&type=style&index=0&id=390407a0&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "390407a0",
  null
  
)

export default component.exports