import { getCosConfig } from '@/api/system/upload.js'
import axios from 'axios'
import { guid, generateFilePath, generateRdStr } from '@/utils/ruoyi.js'
const COS = require('cos-js-sdk-v5');
const Bucket = 'hsj-1253848376';
const Region = 'ap-nanjing';

export default function handleBeforeUpload(files, callback) {
  console.log('files', files)

  let arr = []
  let suffix = files[0].name.substring(files[0].name.lastIndexOf('.'))
  let fileName = generateRdStr()
  let filePath = generateFilePath(fileName, suffix)
  const protocol = location.protocol === 'https:' ? 'https:' : 'http:';
  console.log('filePath,fileName,suffix:', filePath,fileName,suffix)
  // let SecretId = '', SecretKey = ''

    getCosConfig().then(function (res) {
      const cos = new COS({
        SecretId: res.data.tmpSecretId,
        SecretKey: res.data.tmpSecretKey,
        SecurityToken: res.data.sessionToken,
        // Bucket: Bucket,
        // Region: Region
      });
      console.log('files', files)
      cos.putObject({
        Bucket: Bucket /* 存储桶 */,
        Region: Region /* 存储桶所在地域，必须字段 */,
        // Key: files[0].name,
        // Key: fileName + suffix,
        Key: filePath,
        StorageClass: 'STANDARD', // 上传模式, 标准模式
        Body: files[0], // 上传文件对象
        onProgress: function (info) {
          console.log('上传进度', JSON.stringify(info))
        }
      }, function (err, data) {
        console.log('err', err)
        if (data.statusCode == 200) {
          console.log('data上传成功后的数据', data)
          arr.push({
            uid: guid(8, 10),
            status: 'done',
            name: fileName,
            halfUrl: fileName + suffix,
            path: protocol + '//' + data.Location,
            url: protocol + '//' + data.Location,
            cover: ''
          });
          callback(arr)
        } else {
          console.log('图片上传失败，请稍后重试')
        }
      })
    })
  // promise1.then((res1) => {
  //   const cos = new COS({
  //     SecretId: res1.data.tmpSecretId,
  //     SecretKey: res1.data.tmpSecretKey,
  //     SecurityToken: res1.data.sessionToken,
  //     // Bucket: Bucket,
  //     // Region: Region
  //   });
  //   console.log('files', files)
  //   cos.putObject({
  //     Bucket: Bucket /* 存储桶 */,
  //     Region: Region /* 存储桶所在地域，必须字段 */,
  //     // Key: files[0].name,
  //     // Key: fileName + suffix,
  //     Key: filePath,
  //     StorageClass: 'STANDARD', // 上传模式, 标准模式
  //     Body: files[0], // 上传文件对象
  //     onProgress: function (info) {
  //       console.log('上传进度', JSON.stringify(info))
  //     }
  //   }, function (err, data) {
  //     console.log('err', err)
  //     if (data.statusCode == 200) {
  //       console.log('data上传成功后的数据', data)
  //       arr.push({
  //         uid: guid(8, 10),
  //         status: 'done',
  //         name: fileName,
  //         halfUrl: fileName + suffix,
  //         path: protocol + '//' + data.Location,
  //         url: protocol + '//' + data.Location,
  //         cover: ''
  //       });
  //       callback(arr)
  //     } else {
  //       console.log('图片上传失败，请稍后重试')
  //     }
  //   })
  // })
}