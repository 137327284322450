<template>
  <div class="clearfix">
    <a-upload
      :list-type="type == 'image' ? 'picture-card' : 'picture'"
      :show-upload-list="type == 'image' ? true : type == 'video' ? false : true"
      :before-upload="beforeUpload"
      :customRequest="handleChange"
      :disabled="disabled"
      @preview="handlePreview"
      @change="changeImg"
      :multiple="count === 1 ? false : true"
      :default-file-list="defaultList"
      :file-list="fileList"
    >
      <!-- <div v-if="type == 'video'">
        <div class="imgbox" v-for="(item, index) in fileList" :key="index" @click="handlePreviewVideo(item)">
          <div class="videomask">
            <a-icon class="icon" type="play-circle" />
          </div>
          <img :src="item.path + '?x-oss-process=image/resize,m_fill,h_422,w_350'" alt="" class="response"
            style="width: 100px;">
        </div>
      </div> -->
      <div v-if="fileList.length < count && !disabled">
        <span v-if="type == 'image'">
          <a-icon :type="loading ? 'loading' : 'plus'" />
          <div class="ant-upload-text">
            上传
          </div>
        </span>
        <!-- 文件类型  -->
        <div v-if="type == 'file'">
          <a-button> <a-icon type="upload" /> 上传 </a-button>
        </div>
        <div v-if="type == 'video'">
          <div class="imgbox" v-for="(item, index) in fileList" :key="index" @click="handlePreviewVideo(item)">
            <div class="videomask">
              <a-icon class="icon" type="play-circle" />
            </div>
            <img :src="item.path + '?x-oss-process=image/resize,m_fill,h_422,w_350'" alt="" class="response"
                 style="width: 100px;">
          </div>
        </div>
      </div>
    </a-upload>
<!--    <span v-if="type == 'file'" style="color: red">建议上传文件大小 100MB</span>-->
<!--    <span v-if="type == 'image'" style="color: red">建议上传图片尺寸：204x215px</span>-->
<!--    <span v-if="type == 'video'" style="color: red">建议上传视频大小 10MB</span>-->
    <!-- <span v-if="value && type == 'file'">
      <br>
      <a :href="value" target="_blank">{{ value }}</a>
    </span> -->


    <a-modal :visible="previewVisible" :footer="null" @cancel="handleCancel">
      <video v-show="isVideo" style="width: 100%" :src="previewImage" ref="myVideo"
             :poster="previewImage + '?x-oss-process=video/snapshot,t_7000,f_jpg,w_800,m_fast'" :controls="true"></video>
      <img v-show="!isVideo" style="width: 100%" :src="previewImage" />
    </a-modal>
  </div>
</template>

<script>
function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });
}
// import storage from 'store'
// import { ACCESS_TOKEN } from '@/store/mutation-types'
import UploadFileToOSS from '@/utils/upload-file-to-oss.js'
export default {
  name: 'ImageUpload',
  props: {
    value: {
      type: String,
      default: ''
    },
    // 上传的文件类型 image图片 file文件
    type: {
      type: String,
      default: 'image'
    },
    count: {
      type: Number,
      default: 1
    },
    defaultList: {
      type: Array,
      default() {
        return []
      }
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  watch: {
    defaultList: {
      handler(val) {
        this.fileList = val
        console.log('fileList', this.fileList)
        this.$forceUpdate()
      },
      deep: true,
      immediate: true
    },
  },
  components: {
  },
  created() {
    // this.$nextTick(() => {
    //   setTimeout(()=>{
    //     this.fileList = this.defaultList
    //   },1000)
    // })
    // console.log('fileList', this.fileList)
  },

  data() {
    return {
      loading: false,
      open: false,
      isVideo: false,
      // uploadImgUrl: process.env.VUE_APP_BASE_API + '/common/upload',
      // headers: {
      //   Authorization: 'Bearer ' + storage.get(ACCESS_TOKEN)
      // },
      previewVisible: false,
      previewImage: '',
      fileList: [],
    }
  },
  mounted() {
  },
  methods: {
    beforeUpload(file) {
      // 文件类型(file.type)、大小限制(file.size)
      if(this.type === 'image'){
        const imgType = file.type === 'image/jpeg' || file.type === 'image/png'
        if (!imgType) {
          this.$message.error("上传图片只能是 JPG和png 格式!");
          return;
        }
        const isLt2M = file.size / 1024 / 1024 < 100
        if (!isLt2M) {
          this.$message.error('图片大小限制 100MB!')
          return;
        }
        return isLt2M
      }else if(this.type === 'video'){
        // 检查文件类型
        if (file.type !== 'video/mp4') {
          this.$message.error('请上传MP4格式的视频文件');
          return;
        }
        const isLt2M = file.size / 1024 / 1024 < 100
        if (!isLt2M) {
          this.$message.error('视频大小限制 100MB!')
          return;
        }
        return isLt2M
      }else{
        const isLt2M = file.size / 1024 / 1024 < 100
        if (!isLt2M) {
          this.$message.error('文件大小限制 100MB!')
          return;
        }
        return isLt2M
      }
    },
    /**自定义上传事件 */
    async handleChange(info) {
      // if (info.file.status === 'uploading') {
      //   this.loading = true
      //   return
      // }
      // if (info.file.status === 'done') {
      //   if (info.file.response.code !== 200) {
      //     this.$message.error('上传失败' + info.file.response.msg)
      //     this.loading = false
      //     return
      //   }
      //   this.loading = false
      //   // this.$emit('input', info.file.response.url)
      // }

      new UploadFileToOSS([info.file], {
        finally: (res) => {
          this.fileList = this.fileList.concat(res)
          this.$emit('input', this.fileList)
        },
      });
    },
    /**删除图片 */
    changeImg(info) {
      if (info.file.status === "removed") {
        if (this.count === 1) {
          this.fileList = []
          this.$emit('input', this.fileList)
        } else {
          let index = this.fileList.findIndex(ele => ele.uid === info.file.uid)
          this.fileList.splice(index, 1)
          this.$emit('input', this.fileList)
        }
        this.$forceUpdate()
      }
    },
    /**图片预览 */
    async handlePreview(file) {
      this.isVideo = false
      if (!file.url && !file.preview) {
        file.preview = await getBase64(file.originFileObj);
      }
      this.previewImage = file.url || file.preview;
      this.previewVisible = true;
    },
    handlePreviewVideo(info) {
      this.isVideo = true
      this.previewImage = info.url
      this.$nextTick(() => {
        this.$refs.myVideo.play()
      })
    },
    handleCancel() {
      this.previewVisible = false
    }
  }
}
</script>
<style lang="less" scoped>
// img {
//   width: 128px;
//   height: 128px;
// }

/* you can make up upload button and sample style by using stylesheets */
.ant-upload-select-picture-card i {
  font-size: 32px;
  color: #999;
}

.ant-upload-select-picture-card .ant-upload-text {
  margin-top: 8px;
  color: #666;
}

.imgbox {
  width: 100px;
  height: 100px;
  overflow: hidden;
  background-color: #f7f7f7;
  position: relative;
  cursor: pointer;
}

.videomask {
  position: absolute;
  z-index: 10;
  width: 100px;
  height: 100px;
  line-height: 100px;
  background: rgba(0, 0, 0, .3);
  text-align: center;

  .icon {
    display: inline-block;
    font-size: 26px !important;
    color: #fff !important;
  }
}
</style>
