<template>
  <div :class="wrpCls">
    <a-space size="middle">
<!--      <a-tooltip placement="bottom">-->
<!--        <template slot="title">-->
<!--          客服-->
<!--        </template>-->
<!--        <a-icon type="message" @click="loginKf" :style="{ fontSize: '20px' }"/>-->
<!--      </a-tooltip>-->
<!--      <a-tooltip placement="bottom">-->
<!--        <template slot="title">-->
<!--          文档地址-->
<!--        </template>-->
<!--        <a-icon type="question-circle-o" @click="toDoc" :style="{ fontSize: '20px' }"/>-->
<!--      </a-tooltip>-->
      <screenfull />
      <notice-icon v-hasPermi="['system:notice:list']" style="cursor: pointer;"/>
      <avatar-dropdown :menu="showMenu" :current-user="currentUser" :class="prefixCls" />
      <!-- 暂只支持中文，国际化可自行扩展 -->
      <!-- <select-lang :class="prefixCls" /> -->
    </a-space>
  </div>
</template>

<script>
import AvatarDropdown from './AvatarDropdown'
import NoticeIcon from '@/components/NoticeIcon'
import Screenfull from '@/components/Screenfull'
import SelectLang from '@/components/SelectLang'
import { mapGetters } from 'vuex'

export default {
  name: 'RightContent',
  components: {
    AvatarDropdown,
    NoticeIcon,
    Screenfull,
    SelectLang
  },
  props: {
    prefixCls: {
      type: String,
      default: 'ant-pro-global-header-index-action'
    },
    isMobile: {
      type: Boolean,
      default: () => false
    },
    topMenu: {
      type: Boolean,
      required: true
    },
    theme: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      showMenu: true,
      currentUser: {},
      docUrl: process.env.VUE_APP_BASE_API +'/doc',
      githubUrl: 'http://gitlab.niurenjianzhan.com/zhangxun/java-framework',
      ossHost: process.env.VUE_APP_kfURL,
    }
  },
  computed: {
    wrpCls () {
      return {
        'ant-pro-global-header-index-right': true,
        [`ant-pro-global-header-index-${(this.isMobile || !this.topMenu) ? 'light' : this.theme}`]: true
      }
    },
    ...mapGetters([
      'avatar',
      'nickname',
      'token',
      'userInfo'
    ])
  },
  mounted () {
    setTimeout(() => {
      this.currentUser = {
        name: 'RuoYi'
      }
    }, 1500)
  },
  methods: {
    toDoc () {
      window.open(this.docUrl)
    },
    toGithub () {
      window.open(this.githubUrl)
    },
    loginKf(){
      console.log('avatar:', this.avatar)
      console.log('nickname:', this.nickname)
      console.log('token:', this.token)
      console.log('userInfo', this.userInfo)
      console.log('usersig', this.userInfo.userSign)
      let user = this.userInfo
      var id = user.userId;
      var token= this.token;
      var avatar = this.avatar;
      var name = "客服";
      // let openUrl = this.ossHost + 'tencentkf/dist?id='+id+"&avatar="+avatar+"&token="+token+"&name="+name+'&usersig='+user.userSig;
      let openUrl = this.ossHost + '?id='+id+'&usersig='+user.userSign;
      console.log("打开的客服连接是:"+openUrl);
      window.open(openUrl, 'kfWidow',"444");
    },
  }
}
</script>
